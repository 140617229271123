// Bluecscale: buttons, checkboxes, text-highlights, hover state
$colorActiveBlue: #0072ce;
$colorBrandBlue: #1e3575;
$colorGrayBlue: #2c3e50;

// Grayscale: backgrounds, borders, and shadows
$color-white: #ffffff; // backgrounds
$color-gray-0: #f5f5f5; // backgrounds
$color-gray-1-2: #ececec;
$color-gray-1: #d9d9d9; // lines & borders
$color-gray-2: #b1b3b3;
$color-gray-3: #73767b; // disabled text
$color-gray-4: #505357; // body text
$color-gray-5: #2b2b2b; // headlines
$color-background-gray: #bdbdbd;

// Table
$color-background-headers: #b3ce4b;
$color-font-selected: #ffe400;
$color-font-rejected: #ffe400;
$color-background-rejected: #ff0000;
$color-background-accepted: #008000;
$color-background-warning: #ffff00;
$color-edit-icon-outline: #2d95c4;
$color-edit-icon-fill: #246999;
$color-delete-icon-outline: #c1382a;
$color-delete-icon-fill: #86281d;

// Element design
$ios-black-gradient: linear-gradient(0deg, rgba(60, 60, 61, 1) 0%, rgba(0, 0, 0, 1) 51%, rgba(93, 88, 88, 1) 100%);
$ios-button-gradient: linear-gradient(0deg, rgba(68, 101, 138, 1) 0%, rgba(126, 148, 171, 1) 100%);
$ios-selection-background-gradient: linear-gradient(
  0deg,
  rgba(65, 65, 72, 1) 0%,
  rgba(255, 255, 255, 1) 8%,
  rgba(255, 255, 255, 1) 50%,
  rgba(255, 255, 255, 1) 92%,
  rgba(65, 65, 72, 1) 100%
);
$ios-highlight-color: #bfbfdc;
$borderRadius: 5px;

// Common elements
$headerHeight: 90px;
$footerHeight: 75px;
$loginContainerHeight: 88%;
$generalInfoHeight: 40px;
$totalGeneralInfoHeight: 56px; // includes padding
$totalGeneralInfoHeightMobile: 104px; // includes padding
$mainContentHeight: calc(100% - #{$totalGeneralInfoHeight} - #{$headerHeight} - #{$footerHeight});
$mainContentHeightMobile: calc(100% - #{$totalGeneralInfoHeightMobile} - #{$headerHeight} - #{$footerHeight});
$formHeight: 185px;
$tableHeight: calc(100% - #{$formHeight});
$tableHeightMobile: calc(100% - #{$formHeight});
$summaryTableHeight: calc(100% - #{$totalGeneralInfoHeight});
$summaryTableHeightMobile: calc(100% - #{$totalGeneralInfoHeightMobile});
$headerPadding: 10px;

// Breakpoints
$mobile-breakpoint: 650px;
$form-breakpoint: 771px;
$min-app-height: 500px;
$min-content-width: 882px;
$min-content-height: 400px;
